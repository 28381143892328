import React from "react";

// Import icons from a library such as react-icons
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTiktok,
  FaEnvelope,
  FaFileInvoice,
  MdOutlineEmail,
} from "react-icons/fa";

import { Button } from "@material-tailwind/react";

import logo from "./fl1-logo.png";

const App = () => {
  const onOpenUrl = (url) => {
    window.open(url, "_blank");
  };
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="mb-4 flex items-center justify-center flex-col">
        {/* Logo and Bio */}
        <img src={logo} alt="Logo" className="w-20 h-20 mb-2" />
        <p>
          UK based Web design, software development and video production from
          FL1 Digital.
        </p>
      </div>
      <div className="space-y-2">
        {/* Link Buttons */}
        <Button
          variant="gradient"
          className="flex items-center gap-3 w-[250px]"
          onClick={() => onOpenUrl("https://www.facebook.com/fl1digital")}
        >
          <FaFacebook /> Facebook
        </Button>
        <Button
          variant="gradient"
          className="flex items-center gap-3 w-[250px]"
          onClick={() => onOpenUrl("https://www.instagram.com/fl1digital/")}
        >
          <FaInstagram /> Instagram
        </Button>
        <Button
          variant="gradient"
          className="flex items-center gap-3 w-[250px]"
          onClick={() => onOpenUrl("https://www.youtube.com/fl1group")}
        >
          <FaYoutube /> YouTube
        </Button>
        <Button
          variant="gradient"
          className="flex items-center gap-3 w-[250px]"
          onClick={() => onOpenUrl("https://www.tiktok.com/@fl1digital")}
        >
          <FaTiktok /> TikTok
        </Button>
        <Button
          variant="gradient"
          className="flex items-center gap-3 w-[250px]"
          onClick={() => onOpenUrl("mailto:hello@fl1.digital")}
        >
          <FaEnvelope /> Email
        </Button>
        <Button
          variant="gradient"
          className="flex items-center gap-3 w-[250px]"
          color="red"
          onClick={() => onOpenUrl("https://fl1digital.com/get-a-quote/")}
        >
          <FaFileInvoice /> Get a Quote
        </Button>
      </div>
    </div>
  );
};

export default App;
